/**
 * @description get token form localStorage
 * @param tokenType: string
 */
export const getToken = (tokenType: string): string | null => {
  return window.localStorage.getItem(tokenType);
};

/**
 * @description save token into localStorage
 * @param tokenType: string
 * @param token: string
 */
export const saveToken = (tokenType: string, token: string): void => {
  window.localStorage.setItem(tokenType, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem("authToken");
  window.localStorage.removeItem("docsToken");
  window.localStorage.removeItem("username");
};

export default { getToken, saveToken, destroyToken };
