import store from "@/store";
import { authAPI } from "@/api/auth";
import { publicAPI } from "@/api/public";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {
  Module,
  Action,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import jwt from "@/api/jwt";

export interface UserAuthInfo {
  error: unknown;
  isAuthenticated: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "Auth" })
class AuthModule extends VuexModule implements UserAuthInfo {
  error = "";
  messageInfo = "";
  isAuthenticated = !!jwt.getToken("authToken");

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns string
   */
  get getError(): string {
    return this.error;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.error = error;
  }

  @Mutation
  [Mutations.SET_AUTH](loginResponse: { accessToken: string }) {
    this.isAuthenticated = true;
    this.error = "";
    jwt.saveToken("authToken", loginResponse.accessToken);
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.error = "";
    jwt.destroyToken();
  }

  @Mutation
  [Mutations.SET_MESSAGE_FORGOT_PASSWORD](forgotPasswordResponse) {
    this.messageInfo = forgotPasswordResponse;
  }

  @Action
  async [Actions.LOGIN](credentials: { username: string; password: string }) {
    try {
      const loginResponse = await authAPI.login(credentials);
      this.context.commit(Mutations.SET_AUTH, loginResponse);
    } catch ({ response }) {
      this.context.commit(Mutations.SET_ERROR, response.data.error);
    }
  }

  @Action
  async [Actions.FORGOT_PASSWORD](credentials: { email: string; "g-recaptcha-response": string }) {
    try {
      const forgotPasswordResponse = await authAPI.forgotPassword(credentials);
      this.context.commit(Mutations.SET_MESSAGE_FORGOT_PASSWORD, forgotPasswordResponse);
    } catch ({ response }) {
      this.context.commit(Mutations.SET_ERROR, response.data.error);
    }
  }

  @Action
  async [Actions.RESETTING_PASSWORD](payload) {
    try {
      await publicAPI.resettingPassword(payload.credentials, payload.token);
      this.context.commit(Mutations.SET_ERROR, "");
    } catch ({ response }) {
      if (response?.data?.meta?.message?.text) {
        this.context.commit(Mutations.SET_ERROR, response.data.meta.message.text);
      } else {
        this.context.commit(Mutations.SET_ERROR, "Token expired or already used.")
      }
    }
  }

  @Action
  async [Actions.REGISTRATION](payload) {
    try {
      await publicAPI.registration(payload.credentials, payload.token);
    } catch ({ response }) {
      this.context.commit(Mutations.SET_ERROR, response.data.meta.message.text);
    }
  }

  @Action
  async [Actions.CHANGE_PASSWORD](credentials) {
    try {
      const refresh = await authAPI.refreshToken();
      jwt.saveToken("authToken", refresh["accessToken"]);
      await authAPI.updateUser(credentials);
      this.context.commit(Mutations.SET_ERROR, "");
    } catch ({ response }) {
      this.context.commit(Mutations.SET_ERROR, response.data.meta.message.text);
    }
  }

  @Action
  async [Actions.LOGOUT]() {
    try {
      await authAPI.logout();
      this.context.commit(Mutations.PURGE_AUTH);
    } catch ({ response }) {
      this.context.commit(Mutations.SET_ERROR, response.data.error);
    } finally {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}

export default getModule(AuthModule);
