enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  LOGIN = "login",
  CHANGE_PASSWORD = "changePassword",
  RESETTING_PASSWORD = "resettingPassword",
  REGISTRATION = "registration",
  VERIFY_AUTH = "verifyAuth",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  EXPIRED_SESSION = "expiredSession",

  // action User
  FETCH_USER = "fetchUser",
  FETCH_CUSTOMERS = "fetchCustomers",
  SELECT_CUSTOMER = "selectCustomer",
  FETCH_DOCUMENTS = "fetchDocuments",
  
  UPDATE_PROFILE = "updateProfile",
  SWITCH_LANG = "switchLang",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_EXPIRED_SESSION = "setExpiredSession",
  SET_MESSAGE_FORGOT_PASSWORD = "setMessageforgotPassword",

  // mutation User
  SET_USER = "setUser",
  INCREMENT_COMPONENT_USER_KEY = "incrementComponentUser",
  SET_CUSTOMERS = "setCustomers",
  SET_CUSTOMER = "setCustomer",

  SET_DOCUMENTS = "setDocuments",
  SET_LANG= "setLang",
}

enum Request {
  // request types
  LOGIN = "/auth/login",
  USER_CURRENT = "/v1/users/current",
  USER = "/v1/users",
  CUSTOMERS = "/v1/customers",
  ACCESS_TO = "/auth/access",
  ACCESS_UP = "/auth/access-up",
}

export { Actions, Mutations, Request };
