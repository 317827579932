import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import AuthModule from "@/store/modules/AuthModule";
import { Mutations } from "@/store/enums/StoreEnums";
import { saveToken } from "@/api/jwt";
import i18n from "@/plugins/i18n/i18n";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    beforeEnter: (to, from, next) => {
      if (AuthModule.isAuthenticated) {
        next();
      } else {
        next("/sign-in");
      }
    },
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/my-profile",
        name: "my-profile",
        component: () => import("@/views/UserProfile.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/customers",
        name: "customers",
        component: () => import("@/views/Customers.vue"),
      },
      {
        path: "/customers/:id",
        name: "customer-details",
        component: () => import("@/views/CustomerDetails.vue"),
        beforeEnter: (_to, from, next) => {
          if (!from.name && !from.params.id) {
            router.push({ name: "customers" });
          }
          next();
        },
      },
    ],
  },
  {
    path: "/:lang",
    component: () => import("@/components/page-layouts/Auth.vue"),
    beforeEnter: (to, from, next) => {
      const locale = to.params.lang;
      if (locale === "de" || locale === "en" || locale === "fr") {
        saveToken("lang", locale);
        i18n.locale = locale;
      }
      next("/");
    },
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/register/:token",
        name: "register",
        component: () =>
          import("@/views/authentication/basic-flow/Registration.vue"),
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/basic-flow/SignIn.vue"),
        // redirect if user is authenticated
        beforeEnter: (to, from, next) => {
          if (AuthModule.isAuthenticated) {
            next("/dashboard");
          } else {
            next();
          }
        }
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/password-resetting/:token",
        name: "password-resetting",
        component: () =>
          import("@/views/authentication/basic-flow/PasswordResetting.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
