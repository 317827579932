import { createApp } from "vue";
import App from "./App.vue";

import router from "@/router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/plugins/i18n/i18n";
import { VueReCaptcha } from "vue-recaptcha-v3";

import { initApexCharts } from "@/plugins/apexcharts";
import { initInlineSvg } from "@/plugins/inline-svg";
import { initVeeValidate } from "@/plugins/vee-validate";
import Yup from "@/plugins/yup";

import "@/plugins/prismjs";
import "bootstrap";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
});

initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

Yup.setLocale({
  mixed: {
    required: () => i18n.global.t("error.validation.message.required"),
    oneOf: () => i18n.global.t("error.validation.message.password.must.match"),
  },
  string: {
    matches: () => i18n.global.t("error.need.one.special.character"),
    min: () => i18n.global.t("error.need.8.characters"),
    email: () => i18n.global.t("error.not.valid.email"),
  },
});

app.mount("#app");
