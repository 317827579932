import router from '@/router';
import Swal from "sweetalert2/dist/sweetalert2.js";
import i18n from '@/plugins/i18n/i18n';

export function alertSessionExpired(): void {
  Swal.fire({
    icon: "info",
    text: i18n.global.t("login.expiration.warning"),
    buttonsStyling: false,
    allowOutsideClick: false,
    confirmButtonText: i18n.global.t("login.expiration.go.to.login"),
    customClass: {
      confirmButton: "btn btn-primary",
    },
    showClass: {
      backdrop: "swal-background",
    },
  }).then(async (result: { value: boolean; [key: string]: boolean }) => {
    if (result.value) {
      router.push("/sign-in");
      window.location.reload();
    }
  });
}
