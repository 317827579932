import { createI18n } from "vue-i18n/index";
import de from "@/plugins/i18n/de.json";
import en from "@/plugins/i18n/en.json";
import fr from "@/plugins/i18n/fr.json";

const messages = {
  de: de,
  en: en,
  fr: fr,
};

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  messages,
  locale: "de",
});

export default i18n;
