import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";
import { setupInterceptorsTo } from "./interceptors";

/**
 * @description service auth to call HTTP request via Axios
 */
export default class AuthService {
  private axiosInstance: AxiosInstance;
  public baseURL: string = process.env.VUE_APP_API_ELECTRIS_AUTH;

  constructor() {
    this.axiosInstance = setupInterceptorsTo(
      axios.create({
        baseURL: this.baseURL,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Accept": "*/*",
        },
      })
    );
  }

  /**
   * @description HTTP request to call API
   * @param config: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
  */
  private async axiosCall<T>(config: AxiosRequestConfig) {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * @description POST HTTP request to login
   * @param credentials: { email: string, password: string }
   * @returns Promise<AxiosResponse>
  */
  async login(credentials: {
    username: string;
    password: string;
  }): Promise<AxiosResponse> {
    return this.axiosCall({
      method: "post",
      url: "/auth/login",
      data: {
        ...credentials,
      },
    });
  }

  /**
   * @description POST HTTP request to logout
   * @returns Promise<AxiosResponse>
  */
  async logout(): Promise<AxiosResponse> {
    return this.axiosCall({
      method: "post",
      url: "/auth/logout",
      data: {},
    });
  }

  /**
   * @description PATCH HTTP request to update password user
   * @param credentials: { oldPassword: string; password: string, repeatPassword: string }
   * @returns Promise<AxiosResponse>
  */
  async updateUser(credentials: {
    oldPassword?: string; password?: string, repeatPassword?: string, rejectsAdvertising?: boolean
  }): Promise<AxiosResponse> {
    return this.axiosCall({
      method: "patch",
      url: "/v1/users",
      data: {
        ...credentials,
      },
    });
  }

  /**
   * @description POST HTTP request to forgot password
   * @param credentials: { email: string; "g-recaptcha-response": string }
   * @returns Promise<AxiosResponse>
  */
  async forgotPassword(credentials: {
    email: string; "g-recaptcha-response": string
  }): Promise<AxiosResponse> {
    return this.axiosCall({
      method: "post",
      url: "/auth/user/request-password-reset",
      data: {
        ...credentials,
      },
    });
  }

  /**
   * @description POST HTTP request to refresh token
   * @returns Promise<AxiosResponse>
  */
  async refreshToken(): Promise<AxiosResponse> {
    return this.axiosCall({
      method: "post",
      url: "/auth/refresh",
      data: {
        audience: "auth",
      },
    });
  }

  /**
   * @description POST HTTP request to access to resource
   * @param resource: string
   * @returns Promise<AxiosResponse>
  */
  async accessTo(resource: string): Promise<AxiosResponse> {
    return this.axiosCall({
      method: "post",
      url: "/auth/access",
      data: {
        audience: resource,
      },
    });
  }

  /**
   * @description GET HTTP request to get user info
   * @returns Promise<AxiosResponse>
  */
  async user(): Promise<AxiosResponse> {
    return this.axiosCall({
      method: "get",
      url: "/v1/users/current",
    });
  }

  /**
   * @description GET HTTP request to fetch user contracts
   * @returns Promise<AxiosResponse>
  */
  async fetchCustomers(): Promise<AxiosResponse> {
    return this.axiosCall({
      method: "get",
      url: `/v1/customers?_${Date.now()}`,
    });
  }

  /**
   * @description GET HTTP request to retrieve iframe url 
   * @param id: string
   * @returns Promise<AxiosResponse>
  */
  async fetchDocuments(id: string): Promise<AxiosResponse> {
    return this.axiosCall({
      method: "post",
      url: "/auth/access-up",
      data: {
        customerNumber: id,
      },
    });
  }
}

export const authAPI = new AuthService();
