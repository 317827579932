/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { Request } from "@/store/enums/StoreEnums";
import { alertSessionExpired } from "@/api/utils/PopupSessionExpired";
import JwtService from "@/api/jwt";
import jwtDecode, { JwtPayload } from "jwt-decode";
import router from '@/router';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const authToken = JwtService.getToken("authToken");
  const docsToken = JwtService.getToken("docsToken");
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  if (docsToken && config.url?.includes(Request.ACCESS_UP)) {
    config.headers.Authorization = `Bearer ${docsToken}`;
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  const errorMessage = error.response?.data.error;
  const token = JwtService.getToken("authToken");
  if (errorMessage === "Please log in first." || errorMessage === "Token expired.") {
    JwtService.destroyToken();

    if (token) {
      const decoded = jwtDecode<JwtPayload>(token);
      if (decoded.exp) {
        const differenceExpirationMinutes = Math.floor(
          (Date.now() - decoded.exp * 1000) / (1000 * 60)
        );
        if (differenceExpirationMinutes < 1440) {
          router.push("/sign-in");
          alertSessionExpired();
        } else {
          router.push("/sign-in");
          window.location.reload();
        }
      }
    } else {
      window.location.reload();
    }
  }
  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
