import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";

export default class PublicService {
  private axiosInstance: AxiosInstance;
  public baseURL: string = process.env.VUE_APP_API_ELECTRIS_AUTH;

  constructor() {
      this.axiosInstance = axios.create({
        baseURL: this.baseURL,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Accept": "*/*",
        },
      })
  }

  /**
   * @description HTTP request to call API
   * @param config: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
  */
  private async axiosCall<T>(config: AxiosRequestConfig) {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * @description POST HTTP request to reset password
   * @param credentials: { password: string; repeatPassword: string }, token: string
   * @returns Promise<AxiosResponse>
  */
  async resettingPassword(credentials: {
    password: string; repeatPassword: string
  }, token: string): Promise<AxiosResponse> {
    return this.axiosCall({
      method: "patch",
      url: "/v1/users",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...credentials,
        issue: 'LOST'
      },
    });
  }

  /**
   * @description POST HTTP request to reset password
   * @param credentials: { password: string; repeatPassword: string }, token: string
   * @returns Promise<AxiosResponse>
  */
  async registration(credentials: {
    password: string; repeatPassword: string
  }, token: string): Promise<AxiosResponse> {
    return this.axiosCall({
      method: "patch",
      url: "/auth/users",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...credentials,
        issue: 'NEW'
      },
    });
  }
}

export const publicAPI = new PublicService();
