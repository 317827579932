import * as Yup from 'yup';
import i18n from '@/plugins/i18n/i18n';

Yup.setLocale({
    mixed: {
        required: () => i18n.global.t('error.validation.message.required'),
        oneOf: () => i18n.global.t('error.validation.message.password.must.match'),
    },
    string: {
        matches: () => i18n.global.t("error.need.one.special.character"),
        min: () => i18n.global.t("error.need.8.characters"),
        email: () => i18n.global.t("error.not.valid.email"),
        
    }
});

export default Yup;